import { h } from "preact";

import SubAddFriendsIcon from "../assets/sub_icon_add_friends.svg";
import SubIconActivityIcon from "../assets/sub_icon_activity.svg";
import SubIconChallengesIcon from "../assets/sub_icon_challenges.svg";
import SubIconStatsIcon from "../assets/sub_icon_stats.svg";
import SubIconRecordsIcon from "../assets/sub_icon_records.svg";
import SubIconRankingsIcon from "../assets/sub_icon_rankings.svg";

export default function SubIcon({ iconName, ...props }) {
  switch (iconName) {
    case "sub_add_friends":
      return <SubAddFriendsIcon {...props} />;
    case "sub_icon_activity":
      return <SubIconActivityIcon {...props} />;
    case "sub_icon_challenges":
      return <SubIconChallengesIcon {...props} />;
    case "sub_icon_stats":
      return <SubIconStatsIcon {...props} />;
    case "sub_icon_records":
      return <SubIconRecordsIcon {...props} />;
    case "sub_icon_rankings":
      return <SubIconRankingsIcon {...props} />;
    default:
      return null;
  }
}
