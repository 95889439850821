module.exports = {
  namespace: "inside",
  buttons: {
    deeplink: {
      text: "ÅBEN I ODDSET APP’EN",
    },
    download: {
      text: "DOWNLOAD ODDSET APP’EN",
      link: "https://danskespil.dk/oddset/app",
    },
  },
  deeplink: "https://deeplink.oddset.dk",
  features: [
    {
      icon: "sub_add_friends",
      title: "Følg dine venner og bekendte",
      description:
        "Find dine venner og bekendte og tilføj dem i Oddset Inside, så I kan følge og kommentere hinandens spilforslag, præstationer og meget mere.",
      routes: ["/users/:id", "inside/profil", "inside/profil/:id"],
    },
    {
      icon: "sub_icon_activity",
      title: "Del spilforslag og sejre",
      description:
        "”Tror I på den her?”. Giv dine venner mulighed for at give dig feedback på dine spilforslag og omvendt. Og lad dem se, når rekord-oddset er gået hjem eller du har opnået et nyt trofæ.",
      routes: ["/inside/aktivitet", "/inside/trofaer"],
    },
    {
      icon: "sub_icon_challenges",
      title: "Opret udfordringer",
      description:
        "Lav interne leaderboards og inviter de venner, du vil dyste imod. Om det er Premier League eller Tour de France I dyster i, er helt op til jer selv. Du bestemmer selvfølgelig altid selv, hvad du vil dele med dine venner.",
      routes: ["/inside/udfordringer"],
    },
    {
      icon: "sub_icon_stats",
      title: "Se din egen statistik",
      description:
        "Under statistik kan du få indblik i dit eget spilmønster, og følge din egen performance på tværs af sportsgrene, ligaer og hold. Du kan også sammenligne dig selv med resten af Oddset-spillerne.",
      routes: ["/inside/statistik"],
    },
    {
      icon: "sub_icon_records",
      title: "Vi har samlet dine rekorder",
      description:
        "Under Rekorder har vi samlet dine bedste kuponer og alle dine rekorder. Find dit højeste vinderodds, din største udbetaling eller din længste vinderkombination.",
      routes: ["/inside/rekorder"],
    },
    {
      icon: "sub_icon_rankings",
      title: "Ranglister på tværs",
      description:
        "Se din egen placering på diverse ranglister. I hvilken sportsgren har du optjent flest point?",
      routes: ["/inside/ranglister"],
    },
  ],
  routes: {
    trofaer: {
      title: "Trofæer",
      description:
        "Dit trofæskab er klar til at blive fyldt op med trofæer. Test dig selv i en række spændende udfordringer. Gå på jagt efter Live Champ, Landet Rundt eller det eftertragtede Oddset 100 klubben. Se dine trofæer i Oddset appen.",
      icon: "iconTrophies",
    },
    "trofaer/:id": {
      title: "Trofæer",
      description: "Se dine trofæer i Oddset Inside i Oddset appen.",
      icon: "iconTrophies",
    },
    ranglister: {
      title: "Ranglister",
      description:
        "Se dine ranglister i Oddset Inside og se hvilke sportsgrene du er bedst i.",
      icon: "iconRankings",
    },
    aktivitet: {
      title: "Aktivitet",
      description:
        "Dig og dine venner kan følge hinandens væddemål og trofæer samt give hinanden feedback i aktivitetsfeedet.",
      icon: "iconActivities",
    },
    statistik: {
      title: "Statistik",
      description:
        "Få et overblik over din sidste måneds præstationer, tjek din succesrate på live vs. pre-live og se hvilken sportsgren du klarer dig bedst i. Alt det og meget mere kan ses i Oddset Inside i Oddset appen.",
      icon: "iconStatistics",
    },
    rekorder: {
      title: "Rekorder",
      description:
        "Her har vi samlet dine bedste kuponer og alle dine rekorder. Find dit højeste vinderodds, den højeste udbetaling på en kupon eller den længste vinderkombination.",
      icon: "iconRecords",
    },
    udfordringer: {
      title: "Udfordringer",
      description:
        "Opret leaderboards og dyst med dine venner. I vælger selv hvilket tema det skal have, samt hvilket beløb I vil spille for.",
      icon: "iconChallenges",
    },
    profil: {
      title: "Du er blevet inviteret til Oddset Inside",
      description:
        "En ven eller bekendt har sendt dig en invitation til Oddset Inside. Du skal godkende den for at I kan blive forbundet.",
    },
    "profil/:id": {
      title: "Du er blevet inviteret til Oddset Inside",
      description:
        "En ven eller bekendt har sendt dig en invitation til Oddset Inside. Du skal godkende den for at I kan blive forbundet.",
    },
    "users/:id": {
      title: "Du er blevet inviteret til Oddset Inside",
      description:
        "En ven eller bekendt har sendt dig en invitation til Oddset Inside. Du skal godkende den for at I kan blive forbundet.",
      namespace: false,
    },
  },
};
