import { h } from "preact";
import { useContext } from "preact/hooks";
import DeeplinkButton from "../../components/DeeplinkButton";
import { Config } from "../../index";
import { Link } from "../../components/Button";
import Logo from "./assets/logo.min.svg";
import Question from "./assets/question.min.svg";
import isMobile from "ismobilejs";
import SMSInfo from "async!./components/SMSInfo";

import imgAVIF from "./assets/inside_artwork.avif";
import imgAVIF2x from "./assets/inside_artwork@2x.avif";
import imgWEBP from "./assets/inside_artwork.webp";
import imgWEBP2x from "./assets/inside_artwork@2x.webp";
import imgJPEG from "./assets/inside_artwork.jpeg";
import imgJPEG2x from "./assets/inside_artwork@2x.jpeg";

import TopIcon from "./components/TopIcon";
import InsideFeatures from "./components/InsideFeatures";
import InsideDescription from "./components/InsideDescription";

const Page = ({ route, path }) => {
  const { config } = useContext(Config);
  const mobile = isMobile(window.navigator).any;
  const iconSize = mobile ? 95 : 140;

  return (
    <>
      <div className="header">
        <Logo />
      </div>
      <div className="outerHero">
        <div className="hero">
          <picture>
            <source
              srcset={`${imgAVIF} 1x, ${imgAVIF2x} 2x`}
              type="image/avif"
            />
            <source
              srcset={`${imgWEBP} 1x, ${imgWEBP2x} 2x`}
              type="image/webp"
            />
            <source
              srcset={`${imgJPEG} 1x, ${imgJPEG2x} 2x`}
              type="image/jpeg"
            />
            <img src={imgJPEG} />
          </picture>
        </div>
      </div>
      <div className="content">
        <div className="box">
          <TopIcon
            iconName={route.icon}
            className="topImage"
            style={{
              width: iconSize,
              height: iconSize,
              borderRadius: iconSize / 2,
            }}
          />
          <div className="inside">
            <h1>{route.title}</h1>
            <p>{route.description}</p>
            {mobile && (
              <DeeplinkButton>{config.buttons.deeplink.text}</DeeplinkButton>
            )}
          </div>
          <div className="buttomBoxOverlay">
            {mobile ? (
              <DownloadApp
                text={config.buttons.download.text}
                link={config.buttons.download.link}
              />
            ) : (
              <SMSInfo />
            )}
          </div>
        </div>
        <div className="guide">
          <a href="https://danskespil.dk/oddset/app/installguide">
            <Question /> INSTALLATIONSGUIDE
          </a>
        </div>
        <div className="features">
          <InsideDescription />
          <InsideFeatures path={path} />
        </div>
      </div>
    </>
  );
};

function DownloadApp({ text, link }) {
  return (
    <>
      <p>Har du ikke Oddset app’en endnu?</p>
      <Link as="secondary" href={link}>
        {text}
      </Link>
    </>
  );
}

export default Page;
