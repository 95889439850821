import { h } from "preact";

// import IconTrophies from "../assets/main_icon_trofaer.svg";
import IconRankings from "../assets/main_icon_rankings.svg";
import IconStatistics from "../assets/main_icon_statistics.svg";
import IconActivities from "../assets/main_icon_activities.svg";
import IconChallenges from "../assets/main_icon_udfordringer.svg";
import IconRecords from "../assets/main_icon_records.svg";
import IconDefault from "../assets/default.min.svg";

export default function TopImage({ iconName, ...props }) {
  switch (iconName) {
    // case "iconTrophies":
    //   return <IconTrophies {...props} />;

    case "iconRankings":
      return <IconRankings {...props} />;

    case "iconStatistics":
      return <IconStatistics {...props} />;

    case "iconActivities":
      return <IconActivities {...props} />;

    case "iconChallenges":
      return <IconChallenges {...props} />;

    case "iconRecords":
      return <IconRecords {...props} />;
    default:
      return <IconDefault {...props} />;
  }
}
