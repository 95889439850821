import { h } from "preact";
import { useContext } from "preact/hooks";
import { Config } from "../index";
import { Link } from "./Button";

const DeeplinkButton = ({ children }) => {
  const { urls } = useContext(Config);
  const { pathname } = window.location;

  return <Link href={`${urls.secondary}${pathname}`}>{children}</Link>;
};

export default DeeplinkButton;
