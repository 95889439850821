import { h } from "preact";

export const Button = ({ children, as, ...args }) => {
  return (
    <button class={`button ${as}`} {...args}>
      {children}
    </button>
  );
};

export const Link = ({ children, as, ...args }) => {
  return (
    <a class={`button ${as}`} {...args}>
      {children}
    </a>
  );
};
