import { h } from "preact";
import { useContext } from "preact/hooks";
import { Config } from "../../../index";
import SubIcon from "./SubIcon";

export default function InsideFeatures({ path }) {
  const { config } = useContext(Config);
  const features = config.features.filter((feature) => {
    return !feature.routes.includes(path);
  });
  return (
    <div className="sub_cards">
      {features.map((feature) => (
        <div>
          <SubIcon iconName={feature.icon} />
          <h2>{feature.title}</h2>
          <p>{feature.description}</p>
        </div>
      ))}
    </div>
  );
}
